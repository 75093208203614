import React from 'react';

const NavFallback = () => {
  return (
    <div
      style={{
        height: 64,
        width: '100%'
      }}
    >
      Loading...
    </div>
  );
};

export default NavFallback;
